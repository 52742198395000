import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import CryptoJS from "crypto-js";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            type: "",
            wallet: "",
            showLoading: "block",
            history:[]
        }
    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        var encrypted = CryptoJS.AES.encrypt(process.env.REACT_APP_URL+"/api2/games.php", window.location.href);
        console.log(encrypted.toString())

        // U2FsdGVkX18g6/vuGGV2r1UDOGrc7wYOZIgXrjnmIackUXF6oOymMDsvpuTz8NIAqSLB3PpMko6sPYI/axYWPA==

        axios
            .post(process.env.REACT_APP_URL+"/api2/games.php", form,
            )
            .then((response) => {
                console.log(response.data)
                if(response.status === 200){
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        wallet:response.data.wallet,
                        showLoading:"none",
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {


        
        let getGameName = (e) => {
            if(e.endsWith("_A")){
                return "ANDAR"
            } else if(e.endsWith("_B")){
                return "BAHAR"
            } else {
                return "JODI";
            }
        }
    
        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                    <div style={{display:this.state.showLoading}}>
                        <div class="loader-parent">
                        <div class="loader-block">
                        <div class="loader"></div> 
                        </div>
                        </div>
                    </div>
                    

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Game History</span>
                                </div>


                            </div>

                            <div style={{padding:"15px"}}>

                                { this.state.history && this.state.history.map((result) => {
                                    return (
                                        <div className="transaction_block borderdiv" style={{marginBottom:"10px"}} key={result.sn}>
                                            <span className="date-blocks" style={{fontSize:"16px"}}>{result.bazar.replace("_BA"," BA").replace("_A"," ").replace("_B"," ").replace("_"," ")}</span>
                                            <div style={{padding:"6px"}}>
                                                <div className="game-sections">
                                                    <span className="title">Game Type</span>
                                                    
                                                    <span className="data">
                                                    { getGameName(result.bazar) }
                                                        </span>
                                                </div>
                                                <div className="game-sections">
                                                    <span className="title">Digit</span>
                                                    <span className="data">{result.number}</span>
                                                </div>
                                                <div className="game-sections">
                                                    <span className="title">Point</span>
                                                    <span className="data">{result.amount}</span>
                                                </div>
                                            </div>
                                            
                                            <span className="game-date">{result.date}</span>
                                        </div>
                                    )})}


                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default Wallet;
