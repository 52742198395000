import {useLocation} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import Iframe from "./Iframe";
import {Link} from "react-router-dom";

const Chart = () => {

    let location = useLocation();
    let url = location.state.url;
    
    return (<>
            <div className="RootView">
                <div className="RootWidth">

                    <div className="loginDiv">


                        <div className="toolbar">
                            <div>
                                <img src="../images/back.png"  onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                <span>Live Chat</span>
                            </div>


                        </div>

                        <Iframe source={url} />

                    </div>


                </div>
            </div>


        </>
    );
};

export default Chart;
