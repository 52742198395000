import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import CryptoJS from "crypto-js";

import Swal from "sweetalert2";
import {Link} from "react-router-dom";


const date = new Date();
const defaultValue = date.toLocaleDateString('en-CA');

class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            type: "",
            wallet: "",
            showLoading: "block",
            selectedDate: defaultValue,
            markets:[],
            history:[],
            market:"",
            type:"all",
            open:[],
            close:[],
            jodi:[]
        }
    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        
        axios
            .post("https://desawerplay.com/manage/"+"/api2/market_list.php", form,
            )
            .then((response) => {
                if(response.status === 200){


                    this.setState({
                        showLoading:"none",
                        markets:response.data.result,
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            }).catch((error) => {
                this.setState({
                    showLoading:"none",
                    history:[]
                })
            })
    }


    render() {


        let loginSubmit = () =>{

       
                
    
            var form = new FormData();
    
            form.append("session", localStorage.getItem("token"));
            form.append("mobile", localStorage.getItem("mobile"));
    
            form.append("date", this.state.selectedDate);
            form.append("market", this.state.market);
            form.append("type", this.state.type);
    
            axios
                .post("https://desawerplay.com/manage/"+`/api2/jantri_data.php`,form)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    if(response.data.status === "success"){
                        this.setState({
                            showLoading:"none",
                            markets:response.data.result,
                            open: response.data.data.open ? response.data.data.open : [],
                            // Only update if 'close' exists in the response data
                            close: response.data.data.close ? response.data.data.close : [],
                            // Only update if 'jodi' exists in the response data
                            jodi: response.data.data.jodi ? response.data.data.jodi : [],
                        })
                    } else {
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "No bets available for selected market and date"
                        })
                    }
                })
             
            
        }
        
        let getGameName = (e) => {
            if(e.endsWith("_A")){
                return "ANDAR"
            } else if(e.endsWith("_B")){
                return "BAHAR"
            } else {
                return "JODI";
            }
        }

    
        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                    <div style={{display:this.state.showLoading}}>
                        <div class="loader-parent">
                        <div class="loader-block">
                        <div class="loader"></div> 
                        </div>
                        </div>
                    </div>
                    

                        <div className="loginDiv" style={{ position:this.state.blockPosition, width:"100%", paddingBottom:"15%"}}>


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Game Jantri</span>
                                </div>


                            </div>
                            <div className="card">
                                <span>Select Date</span>
                                <input
                                    className="editbox"
                                    name="date"
                                    defaultValue={defaultValue} 
                                    type="date"
                                    value={this.state.selectedDate}
                                    onChange={(e)=>{this.setState({selectedDate:e.target.value})}}
                                    max={date.toLocaleDateString('en-CA')}
                                    placeholder="Select Date"
                                />
                            
                                <span style={{marginTop:"10px"}}>Select Market</span>
                                
                                <select className="form-select edittext" onChange={(e)=>{this.setState({market:e.target.value})}} >
                                <option value="">Select Market</option>
                                { this.state.markets && this.state.markets.map((result) => (
                                    <option key={result.market} value={result.market}>{result.market}</option>
                                ))}

                                </select>

                                
                                <select className="form-select edittext" onChange={(e)=>{this.setState({type:e.target.value})}} style={{marginTop:"15px"}}>
                                    <option value="all">All</option>
                                    <option value="jodi">Jodi</option>
                                    <option value="single">Haruf</option>
                                </select>

                                <button style={{marginTop:"15px"}} className="button" onClick={loginSubmit}>
                                    <b>Submit</b>
                                </button>


                            </div>
                            { this.state.open && Object.keys(this.state.open).length > 0 && (


                           <>
                           <span>Andar Haruf</span>
                            <div className="main-grid"  style={{marginBottom:"15px"}}>
                                <div>
                                    <span>
                                        No
                                    </span>
                                    <span>
                                        ₹
                                    </span>
                                </div>
                                { this.state.open && this.state.open.map((data) => (
                                    <div key={data.digit}>
                                        <span>
                                            {data.digit}
                                        </span>
                                        <span>
                                            {data.amount}
                                        </span>
                                    </div>
                                ))}
                               
                            </div>
                           </>
                             )}

                           
                                { this.state.close && Object.keys(this.state.close).length > 0 && (


                                <>
                                <span>Bahar Haruf</span>
                                <div className="main-grid"  style={{marginBottom:"15px"}}>
                                    <div>
                                        <span>
                                            No
                                        </span>
                                        <span>
                                            ₹
                                        </span>
                                    </div>
                                    { this.state.close && this.state.close.map((data) => (
                                        <div key={data.digit}>
                                            <span>
                                                {data.digit}
                                            </span>
                                            <span>
                                                {data.amount}
                                            </span>
                                        </div>
                                    ))}
                                    
                                </div>
                                </>
                                )}

{ this.state.jodi && Object.keys(this.state.jodi).length > 0 && (
  <>
    <span  style={{marginTop:"15px"}}>Jodi</span>
    <div >
      { 
        // Split data into chunks of 10
        this.state.jodi.reduce((acc, data, index) => {
          const chunkIndex = Math.floor(index / 10);

          // Create a new array for each chunk
          if (!acc[chunkIndex]) {
            acc[chunkIndex] = [];
          }

          // Push current item into the chunk
          acc[chunkIndex].push(data);

          return acc;
        }, []).map((chunk, rowIndex) => (
          <div key={rowIndex} className="main-grid" style={{marginBottom:"10px"}}>
            {/* First column with "No" and "₹" */}
            <div>
              <span>No</span>
              <span>₹</span>
            </div>
            {/* Following columns with 10 data points */}
            {chunk.map((data, index) => (
              <div key={data.digit}>
                <span>{data.digit}</span>
                <span>{data.amount}</span>
              </div>
            ))}
          </div>
        ))
      }
    </div>
  </>
)}



                        </div>

                     
                    </div>
                </div>


            </>
        );
    }

}

export default Wallet;
